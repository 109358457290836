define("ember-composable-helpers/helpers/slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute([start, end, array]) {
      Ember.set(this, 'array', array);
      return array.slice(start, end);
    },

    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});